import React, { useContext, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import Layout from "../../components/Layout";
import PlainPage from "../../templates/plain-page";
import { firebaseApp, db } from "../../components/firebase/firestore";
import { firebaseEmailSettings } from "../../components/auth/Auth";

const Content = () => {
  return (
    <p>
      commit msg: {process.env.COMMIT_MSG}
      <br />
      commit date: {new Date(process.env.COMMIT_DATE).toLocaleString("en-GB")}
    </p>
  );
};

const PrivatePage = () => {
  return <PlainPage Content={Content} />;
};

export default PrivatePage;

import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import { AuthContext } from "../components/auth/Auth";

const PlainPage = ({
  Content,
  isCentred = false,
  isPrivate = false,
  isAdminOnly = false,
  isFluid = false,
  location = "",
}) => {
  return (
    <Layout>
      <section className="section section--gradient">
        <div className={`container ${isFluid ? "is-fluid" : "is-widescreen"}`}>
          <div className={`columns ${isCentred ? "is-centered" : ""}`}>
            <AuthContext.Consumer>
              {({
                currentUser,
                reduceNotVerifiedUsersCount,
                isAdmin,
                rereadRefUid,
                refUid,
              }) => (
                <Fragment>
                  {isPrivate && currentUser === null ? navigate("/") : ""}
                  {/* isAdmin === 0 before value is read from db. have to check that, since currentUser is loaded faster than isAdmin */}
                  {/* check for SSR, when there is no window */}
                  {isAdminOnly &&
                  !isAdmin &&
                  isAdmin !== 0 &&
                  currentUser !== 0 &&
                  typeof window !== "undefined"
                    ? navigate("/")
                    : ""}
                  {currentUser === 0 ? (
                    <div className="button is-loading-spinner">Loading</div>
                  ) : (
                    ""
                  )}
                  {currentUser !== 0 &&
                  ((isPrivate && currentUser) || !isPrivate)
                    ? (isAdmin || !isAdminOnly) && (
                        <Content
                          currentUser={currentUser}
                          reduceNotVerifiedUsersCount={
                            reduceNotVerifiedUsersCount
                          }
                          location={location}
                          rereadRefUid={rereadRefUid}
                          refUid={refUid}
                        />
                      )
                    : ""}
                </Fragment>
              )}
            </AuthContext.Consumer>
          </div>
        </div>
      </section>
    </Layout>
  );
};

PlainPage.propTypes = {
  Content: PropTypes.func.isRequired,
  isCentred: PropTypes.bool,
};

export default PlainPage;
